import i18n from './i18n';

const roles = {
    admin: {
        role: 'admin',
        name: i18n.t('app.admin.roles.admin')
    },
    developer: {
        role: 'developer',
        name: i18n.t('app.admin.roles.developer')
    },
    licensor: {
        role: 'licensor',
        name: i18n.t('app.admin.roles.licensor')
    },
    recruiter: {
        role: 'recruiter',
        name: i18n.t('app.admin.roles.recruiter')
    }
};

export const allowedRoles = {
    app: {
        get: [roles.admin.role, roles.developer.role, roles.licensor.role, roles.recruiter.role],
        patch: [roles.admin.role, roles.developer.role],
        post: [roles.admin.role, roles.developer.role]
    },
    contract: {
        get: [roles.admin.role, roles.developer.role, roles.licensor.role, roles.recruiter.role],
        patch: [roles.admin.role, roles.licensor.role],
        post: [roles.admin.role, roles.licensor.role]
    },
    customer: {
        get: [roles.admin.role, roles.developer.role, roles.licensor.role, roles.recruiter.role],
        patch: [roles.admin.role, roles.licensor.role],
        post: [roles.admin.role, roles.licensor.role]
    },
    license: {
        get: [roles.admin.role, roles.developer.role, roles.licensor.role, roles.recruiter.role],
        patch: [roles.admin.role, roles.licensor.role],
        post: [roles.admin.role, roles.licensor.role]
    },
    release: {
        get: [roles.admin.role, roles.developer.role, roles.licensor.role, roles.recruiter.role],
        patch: [roles.admin.role, roles.developer.role],
        post: [roles.admin.role, roles.developer.role]
    },
    releaseAsset: {
        get: [roles.admin.role, roles.developer.role, roles.licensor.role, roles.recruiter.role],
        post: [roles.admin.role, roles.developer.role]
    },
    user: {
        get: [roles.admin.role, roles.recruiter.role],
        patch: [roles.admin.role, roles.recruiter.role],
        post: [roles.admin.role, roles.recruiter.role]
    }
};

export default roles;
