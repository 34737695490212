import React from 'react';
import PropTypes from 'prop-types';

import { ReactComponent as IconSpin } from '../../assets/icon-spin.svg';

import classes from './Spinner.module.scss';

function Spinner({ className }) {
    return <IconSpin className={`${classes.Spinner} ${className}`} />
}

Spinner.propTypes = {
    className: PropTypes.string
}

Spinner.defaultProps = {
    className: ''
}

export default Spinner;
