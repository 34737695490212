import jwt from 'jsonwebtoken';

export const isTokenExpired = token => {
    if (!token) {
        return true;
    }

    let isExpired = false;
    const decodedToken = jwt.decode(token, { complete: true });

    if (decodedToken && decodedToken.payload) {
        const dateNow = new Date();
        const tokenExpirationMilliseconds = decodedToken.payload.exp * 1000;

        if (tokenExpirationMilliseconds < dateNow.getTime()) {
            isExpired = true;
        }
    } else {
        isExpired = true;
    }

    return isExpired;
};
