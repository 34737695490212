import React, { Suspense, useContext, useEffect, useState } from 'react';
import { useHistory, Route, Switch } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { ToastContainer } from 'react-toastify';

import i18n from '../../config/i18n';
import { allowedRoles } from '../../config/roles';

import { UserStoreContext } from '../../store/userStore/context';

import Spinner from '../../components/Spinner/Spinner';
import SideNav from '../../components/Navigation/SideNav';

import { ReactComponent as IconAccount } from '../../assets/icon-account.svg';
import { ReactComponent as IconDownload } from '../../assets/icon-download.svg';
import { ReactComponent as IconCellphone } from '../../assets/icon-cellphone.svg';
import { ReactComponent as IconClipboardAccount } from '../../assets/icon-clipboard-account.svg';

import { isTokenExpired } from '../../utils/token';
import { isUserAllowed } from '../../utils/roles';

import classes from './AdminApp.module.scss';

// TODO Breadcrumb component

// Pages
const Login = React.lazy(() => import('./Login/Login'));
const Customers = React.lazy(() => import('./Customers/Customers'));
const ContractsLicenses = React.lazy(() => import('./ContractsLicenses/ContractsLicenses'));
const Apps = React.lazy(() => import('./Apps/Apps'));
const Releases = React.lazy(() => import('./Releases/Releases'));
const ReleaseAssets = React.lazy(() => import('./ReleaseAssets/ReleaseAssets'));
const Users = React.lazy(() => import('./Users/Users'));

const loadingContent = (
    <div className={classes.Loading}>
        <Spinner className={classes.LoadingSpin} />
    </div>
);

function AdminApp() {
    const { loggedUser, actions } = useContext(UserStoreContext);
    const history = useHistory();
    const [allowedRoutes, setAllowedRoutes] = useState({ adminRoutes: [], sideNavRoutes: [] });

    useEffect(() => {
        function logoutIfTokenExpired() {
            const path = history.location.pathname.replace(/\/+$/, '');
            if (path !== '/admin/login' && isTokenExpired(loggedUser.token)) {
                actions.logout();
                history.push('/admin/login');
            }
        }

        logoutIfTokenExpired();

        const unlistenHistory = history.listen(() => {
            logoutIfTokenExpired();
        });

        return () => {
            unlistenHistory();
        };
    }, [history, actions, loggedUser.token]);

    useEffect(() => {
        const path = history.location.pathname.replace(/\/+$/, '');
        if (path === '/admin/login' && !isTokenExpired(loggedUser.token)) {
            if (loggedUser.isAdmin) {
                history.push('/admin/clientes');
            } else if (loggedUser.isLicensor) {
                history.push('/admin/clientes');
            } else if (loggedUser.isDeveloper) {
                history.push('/admin/aplicativos');
            } else if (loggedUser.isRecruiter) {
                history.push('/admin/usuarios');
            }
        }
    }, [
        history,
        loggedUser.isAdmin,
        loggedUser.isDeveloper,
        loggedUser.isLicensor,
        loggedUser.isRecruiter,
        loggedUser.token
    ]);

    useEffect(() => {
        const sideNavRoutes = [];
        const adminRoutes = [];
        if (isUserAllowed(loggedUser.roles, allowedRoles.customer.get)) {
            sideNavRoutes.push({
                Icon: IconAccount,
                link: '/admin/clientes',
                label: i18n.t('app.admin.menu.customers')
            });
            adminRoutes.push({
                path: '/admin/clientes',
                component: (
                    <>
                        <Helmet>
                            <title>{i18n.t('app.admin.pages.title.customers')}</title>
                        </Helmet>
                        <Customers />
                    </>
                )
            });
        }
        if (
            isUserAllowed(loggedUser.roles, allowedRoles.contract.get) ||
            isUserAllowed(loggedUser.roles, allowedRoles.license.get)
        ) {
            adminRoutes.push({
                path: '/admin/contratos',
                component: (
                    <>
                        <Helmet>
                            <title>{i18n.t('app.admin.pages.title.contracts_licenses')}</title>
                        </Helmet>
                        <ContractsLicenses />
                    </>
                )
            });
        }
        if (isUserAllowed(loggedUser.roles, allowedRoles.app.get)) {
            sideNavRoutes.push({
                Icon: IconCellphone,
                link: '/admin/aplicativos',
                label: i18n.t('app.admin.menu.apps')
            });
            adminRoutes.push({
                path: '/admin/aplicativos',
                component: (
                    <>
                        <Helmet>
                            <title>{i18n.t('app.admin.pages.title.apps')}</title>
                        </Helmet>
                        <Apps />
                    </>
                )
            });
        }
        if (isUserAllowed(loggedUser.roles, allowedRoles.releaseAsset.get)) {
            sideNavRoutes.push({
                Icon: IconDownload,
                link: '/admin/pdv',
                label: i18n.t('app.admin.menu.pdv')
            });
            adminRoutes.push({
                path: '/admin/pdv',
                component: (
                    <>
                        <Helmet>
                            <title>{i18n.t('app.admin.pages.title.pdv')}</title>
                        </Helmet>
                        <ReleaseAssets />
                    </>
                )
            });
        }
        if (isUserAllowed(loggedUser.roles, allowedRoles.release.get)) {
            adminRoutes.push({
                path: '/admin/versoes',
                component: (
                    <>
                        <Helmet>
                            <title>{i18n.t('app.admin.pages.title.releases')}</title>
                        </Helmet>
                        <Releases />
                    </>
                )
            });
        }
        if (isUserAllowed(loggedUser.roles, allowedRoles.user.get)) {
            sideNavRoutes.push({
                Icon: IconClipboardAccount,
                link: '/admin/usuarios',
                label: i18n.t('app.admin.menu.users')
            });
            adminRoutes.push({
                path: '/admin/usuarios',
                component: (
                    <>
                        <Helmet>
                            <title>{i18n.t('app.admin.pages.title.users')}</title>
                        </Helmet>
                        <Users />
                    </>
                )
            });
        }

        setAllowedRoutes({ adminRoutes, sideNavRoutes });
    }, [loggedUser.roles]);

    const isUserLogged = !isTokenExpired(loggedUser.token);
    const pageClasses = isUserLogged ? classes.Page : '';
    const pageContentClasses = isUserLogged ? classes.PageContent : '';
    const routes = (
        <>
            {allowedRoutes.adminRoutes.map(({ path, component }) => (
                <Route key={path} path={path} render={() => component} />
            ))}
            <Route
                path='/admin/login'
                render={() =>
                    !isUserLogged && (
                        <>
                            <Helmet>
                                <title>{i18n.t('app.admin.pages.title.login')}</title>
                            </Helmet>
                            <Login />
                        </>
                    )
                }
            />
        </>
    );

    return (
        <div className={pageClasses}>
            {isUserLogged && <SideNav items={allowedRoutes.sideNavRoutes} />}
            <div className={pageContentClasses}>
                <Suspense fallback={loadingContent}>
                    <Switch>{routes}</Switch>
                </Suspense>
                <ToastContainer />
            </div>
        </div>
    );
}

export default AdminApp;
