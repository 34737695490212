import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import Header from './Header/Header';
import Footer from './Footer/Footer';

import classes from './Layout.module.scss';

function Layout({ children, contentClassName, navigationItems, homeLink }) {
    return (
        <Fragment>
            <Header
                navigationItems={navigationItems}
                homeLink={homeLink}
                contentClassName={contentClassName}
            />
            <main className={classes.Main}>{children}</main>
            <Footer contentClassName={contentClassName} />
        </Fragment>
    );
}

Layout.propTypes = {
    children: PropTypes.node.isRequired,
    contentClassName: PropTypes.string,
    navigationItems: PropTypes.arrayOf(
        PropTypes.shape({
            link: PropTypes.string.isRequired,
            label: PropTypes.string.isRequired
        })
    ).isRequired,
    homeLink: PropTypes.string.isRequired
};

Layout.defaultProps = {
    contentClassName: ''
};

export default Layout;
