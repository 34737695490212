import React, { useState } from 'react';
import { Route, Switch } from 'react-router-dom';

import { UserStoreProvider } from './store/userStore/context';

import AppLayout from './pages/AppLayout/AppLayout';
import AdminApp from './pages/AdminApp/AdminApp';

import Context from './utils/context';
import appendWebpClassHtml from './utils/webp';

appendWebpClassHtml();

const App = () => {
    const [initialLoad, setInitialLoad] = useState(false);

    return (
        <Context.Provider
            value={{
                // Initial Load
                initialLoadProp: initialLoad,
                setInitialLoadProp: () => setInitialLoad(true)
            }}
        >
            <Switch>
                <Route
                    path='/(admin)/'
                    component={() => (
                        <UserStoreProvider>
                            <AdminApp />
                        </UserStoreProvider>
                    )}
                />
                <Route path='/' component={AppLayout} />
            </Switch>
        </Context.Provider>
    );
};

export default App;
