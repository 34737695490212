import React from 'react';
import PropTypes from 'prop-types';

import classes from './WebpImage.module.scss';

function WebpImage({ img, source }) {
    let sourceElement;
    if (Array.isArray(source)) {
        sourceElement = source.map(({ srcSet, media, type }) => (
            <source key={media} media={media} srcSet={srcSet} type={type} />
        ));
    } else {
        sourceElement = <source srcSet={source.srcSet} type={source.type} />;
    }
    return (
        <picture className={classes.Picture}>
            {sourceElement}
            <img
                {...img}
                src={img.src}
                alt={img.alt}
            />
        </picture>
    );
}

WebpImage.propTypes = {
    img: PropTypes.shape({
        alt: PropTypes.string.isRequired,
        className: PropTypes.string,
        loading: PropTypes.oneOf(['lazy']),
        src: PropTypes.string.isRequired,
        srcSet: PropTypes.string
    }).isRequired,
    source: PropTypes.oneOfType([
        PropTypes.shape({
            srcSet: PropTypes.string.isRequired,
            type: PropTypes.string.isRequired
        }),
        PropTypes.arrayOf(
            PropTypes.exact({
                media: PropTypes.string.isRequired,
                srcSet: PropTypes.string.isRequired,
                type: PropTypes.string.isRequired
            })
        )
    ]).isRequired
};

export default WebpImage;
