import React from 'react';
import { NavLink } from 'react-router-dom';
import PropTypes from 'prop-types';

function NavigationItem({
    activeClassName,
    className,
    children,
    link,
    onClick
}) {
    return (
        <NavLink
            to={link}
            className={className}
            activeClassName={activeClassName}
            onClick={onClick}
        >
            {children}
        </NavLink>
    );
}

NavigationItem.propTypes = {
    children: PropTypes.node.isRequired,
    link: PropTypes.string.isRequired,
    activeClassName: PropTypes.string,
    className: PropTypes.string,
    onClick: PropTypes.func
};

NavigationItem.defaultProps = {
    className: '',
    activeClassName: '',
    onClick: () => void(0)
};

export default NavigationItem;
