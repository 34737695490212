async function WebpIsSupported() {
    if (!window.createImageBitmap) return false;
    const webpData =
        'data:image/webp;base64,UklGRiQAAABXRUJQVlA4IBgAAAAwAQCdASoCAAEAAQAcJaQAA3AA/v3AgAA=';
    const blob = await fetch(webpData).then(r => r.blob());
    return createImageBitmap(blob).then(
        () => true,
        () => false
    );
}

export default async () => {
    const html = document.getElementsByTagName('html')[0];
    if (await WebpIsSupported()) {
        html.className += ' webp';
    } else {
        html.className += ' no-webp';
    }
};
