import { types } from './reducer';


/**
 * Login middleware responsible to set token, name and roles on local storage.
 * 
 * @param {function} dispatch Dispatch function.
 * @param {object} payload Payload containing token, name and roles.
 */
const loginMiddleware = (dispatch, payload) => {
    const { token, name, roles } = payload;

    localStorage.setItem('token', token);
    localStorage.setItem('name', name);
    localStorage.setItem('roles', JSON.stringify(roles));

    return dispatch({ type: types.LOGIN_USER, payload });
}

/**
 * Logout middleware responsible to remove token, name and roles from local storage.
 * 
 * @param {function} dispatch Dispatch function.
 */
const logoutMiddleware = (dispatch) => {
    localStorage.removeItem('token');
    localStorage.removeItem('name');
    localStorage.removeItem('roles');

    return dispatch({ type: types.LOGOUT_USER });
}

export const setLocalStorageMiddleware = dispatch => {
    return action => {
        switch (action.type) {
            case types.LOGIN_USER:
                return loginMiddleware(dispatch, action.payload);
            case types.LOGOUT_USER:
                return logoutMiddleware(dispatch);
            default:
                throw new Error('Invalid action type.');
        }
    };
};
