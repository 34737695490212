import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import i18n from '../../../../config/i18n';

import { ReactComponent as DownloadIcon } from '../../../../assets/icon-download.svg';
import { ReactComponent as PhoneIcon } from '../../../../assets/icon-phone.svg';
import { ReactComponent as WhatsAppIcon } from '../../../../assets/icon-whatsapp.svg';
import { ReactComponent as LocationIcon } from '../../../../assets/icon-location.svg';

import MapsImageWebp from '../../../../assets/footer-maps.webp';
import MapsImagePng from '../../../../assets/footer-maps.png';

import WebpImage from '../../../../components/WebpImage/WebpImage';

import classes from './Footer.module.scss';

function Footer({ contentClassName }) {
    return (
        <footer className={classes.Footer}>
            <div className={contentClassName}>
                <div className={classes.FooterGrid}>
                    <div className={classes.FooterGroup1}>
                        <p className={classes.FooterGroupTitle}>
                            {i18n.t('app.footer.links')}
                        </p>
                        <Link
                            className={`${classes.FooterGroupText} ${classes.Link}`}
                            to='/produtos'
                        >
                            {i18n.t('app.footer.products')}
                        </Link>
                        <br />
                        <Link
                            className={`${classes.FooterGroupText} ${classes.Link}`}
                            to='/contato'
                        >
                            {i18n.t('app.footer.contact')}
                        </Link>
                    </div>

                    <div className={classes.FooterGroup2}>
                        <p className={classes.FooterGroupTitle}>
                            {i18n.t('app.footer.help')}
                        </p>
                        <DownloadIcon className={classes.FooterIcon} />
                        <a
                            className={`${classes.FooterGroupText} ${classes.Link}`}
                            href='/download/ajuda'
                            download
                        >
                            {i18n.t('app.footer.download')}
                        </a>
                    </div>

                    <div className={classes.FooterGroup3}>
                        <p className={classes.FooterGroupTitle}>
                            {i18n.t('app.footer.contact')}
                        </p>
                        <PhoneIcon className={classes.FooterIcon} />
                        <span className={classes.FooterGroupText}>
                            {i18n.t('app.footer.phone', {
                                number: i18n.t('info.phone')
                            })}
                        </span>
                        <br />
                        <WhatsAppIcon className={classes.FooterIcon} />
                        <a
                            className={`${classes.FooterGroupText} ${classes.Link}`}
                            href='https://api.whatsapp.com/send?phone=5513997139592'
                            target='_blank'
                            rel='noopener noreferrer'
                        >
                            {i18n.t('app.footer.whatsapp', {
                                number: i18n.t('info.whatsapp')
                            })}
                        </a>
                    </div>

                    <div className={classes.FooterGroup4}>
                        <p className={classes.FooterGroupTitle}>
                            {i18n.t('app.footer.opening_hours')}
                        </p>
                        <span className={classes.FooterGroupText}>
                            {i18n.t('app.footer.monday_friday')}
                        </span>
                        <br />
                        <span className={classes.FooterGroupText}>
                            {i18n.t('app.footer.saturday')}
                        </span>
                    </div>

                    <div className={classes.FooterGroup5}>
                        <p className={classes.FooterGroupTitle}>
                            {i18n.t('app.footer.find_us')}
                        </p>
                        <div className={classes.Address}>
                            <LocationIcon
                                className={`${classes.FooterIcon} ${classes.LocationIcon}`}
                            />
                            <span className={classes.FooterGroupText}>
                                {i18n.t('info.address')}
                            </span>
                        </div>
                    </div>

                    <div className={classes.FooterGroup6}>
                        <a
                            href='https://www.google.com/maps/place/Visual+Data+Inform%C3%A1tica/@-23.9610358,-46.3475616,17z/data=!3m1!4b1!4m5!3m4!1s0x94ce033f86ccc36b:0x346728001a87e19e!8m2!3d-23.9610358!4d-46.3453729?hl=pt-BR'
                            target='_blank'
                            rel='noopener noreferrer'
                        >
                            <WebpImage
                                source={{
                                    srcSet: MapsImageWebp,
                                    type: 'image/webp'
                                }}
                                img={{
                                    src: MapsImagePng,
                                    alt: i18n.t('general.location'),
                                    className: classes.FooterMap,
                                    loading: 'lazy',
                                    height: 150,
                                    width: 851
                                }}
                            />
                        </a>
                    </div>
                </div>
            </div>
        </footer>
    );
}

Footer.propTypes = {
    contentClassName: PropTypes.string
};

Footer.defaultProps = {
    contentClassName: ''
};

export default Footer;
