import React, { useState } from 'react';
import PropTypes from 'prop-types';

import NavigationItem from './NavigationItem/NavigationItem';
import classes from './Navigation.module.scss';

function Navigation({ items }) {
    const [isSideMenuOpen, setSideMenuOpen] = useState(false);

    function hideMenu() {
        setSideMenuOpen(false);
    }

    function toggleMenu() {
        setSideMenuOpen(!isSideMenuOpen);
    }

    let sideMenuClasses = [classes.NavigationSideMenu];
    let hamburgerIconClasses = [classes.NavigationHamburgerIcon];
    let backdropClasses = [classes.NavigationBackdrop];
    if (isSideMenuOpen) {
        sideMenuClasses.push(classes.NavigationSideMenuOpen);
        hamburgerIconClasses.push(classes.NavigationHamburgerIconClose);
        backdropClasses.push(classes.NavigationBackdropActive);
    }
    sideMenuClasses = sideMenuClasses.join(' ');
    hamburgerIconClasses = hamburgerIconClasses.join(' ');
    backdropClasses = backdropClasses.join(' ');

    return (
        <div className={classes.NavigationContainer}>
            <nav className={classes.Navigation}>
                <div
                    className={backdropClasses}
                    onClick={hideMenu}
                ></div>
                <div
                    className={classes.NavigationHamburger}
                    onClick={toggleMenu}
                >
                    <div className={hamburgerIconClasses}></div>
                </div>
                <div className={sideMenuClasses}>
                    {items.map(({ link, label }) => (
                        <NavigationItem
                            key={link}
                            className={classes.NavigationItem}
                            activeClassName={classes.NavigationItemActive}
                            link={link}
                            onClick={hideMenu}
                        >
                            {label}
                        </NavigationItem>
                    ))}
                </div>
            </nav>
        </div>
    );
}

Navigation.propTypes = {
    items: PropTypes.arrayOf(
        PropTypes.shape({
            link: PropTypes.string.isRequired,
            label: PropTypes.string.isRequired
        })
    ).isRequired
};

export default Navigation;
