import React from 'react';
import PropTypes from 'prop-types';

import Logo from '../../assets/logo.png';

import NavigationItem from './NavigationItem/NavigationItem';
import classes from './SideNav.module.scss';

function SideNav({ items }) {
    return (
        <nav className={classes.SideNav}>
            <img src={Logo} alt='Logo' className={classes.Logo} />
            {items.map(({ Icon, link, label }) => (
                <div className={classes.SideNavItemContainer} key={link}>
                    <NavigationItem
                        className={classes.SideNavItem}
                        activeClassName={classes.SideNavItemActive}
                        link={link}
                    >
                        {Icon && <Icon />}
                        {label}
                    </NavigationItem>
                </div>
            ))}
        </nav>
    );
}

SideNav.propTypes = {
    items: PropTypes.arrayOf(
        PropTypes.shape({
            Icon: PropTypes.object,
            link: PropTypes.string.isRequired,
            label: PropTypes.string.isRequired
        })
    ).isRequired
};

export default SideNav;
