import React, { createContext, useReducer } from 'react';
import { userReducer, initialState } from './reducer';
import { userActions } from './actions';
import { setLocalStorageMiddleware } from './middleware';

const UserStoreContext = createContext(initialState);

const UserStoreProvider = ({ children }) => {
    const [loggedUser, dispatch] = useReducer(userReducer, initialState);

    const dispatchWithMiddleware = setLocalStorageMiddleware(dispatch);

    const actions = userActions(loggedUser, dispatchWithMiddleware);

    return (
        <UserStoreContext.Provider value={{ loggedUser, dispatchWithMiddleware, actions }}>
            {children}
        </UserStoreContext.Provider>
    );
};

export { UserStoreContext, UserStoreProvider };
