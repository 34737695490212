import { getUserRoles } from '../../utils/roles';

const types = {
    LOGIN_USER: 'LOGIN_USER',
    LOGOUT_USER: 'LOGOUT_USER'
};

let userRoles = localStorage.getItem('roles');
userRoles = userRoles ? JSON.parse(userRoles) : [];

const initialState = {
    token: localStorage.getItem('token'),
    name: localStorage.getItem('name'),
    roles: userRoles,
    ...getUserRoles(userRoles)
};

const userReducer = (state, action) => {
    switch (action.type) {
        case types.LOGIN_USER:
            const { name, roles, token } = action.payload;
            return { ...state, name, roles, token, ...getUserRoles(roles) };
        case types.LOGOUT_USER:
            return { ...initialState };
        default:
            return state;
    }
};

export { initialState, types, userReducer };
