import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import Navigation from '../../../../components/Navigation/Navigation';

import LogoImage from '../../../../assets/logo.png';

import classes from './Header.module.scss';

function Header({ contentClassName, navigationItems, homeLink }) {
    return (
        <header className={classes.Header}>
            <div className={`${classes.Content} ${contentClassName}`}>
                <Link
                    className={classes.LogoContainer}
                    to={homeLink}
                >
                    <img src={LogoImage} alt='Logo' className={classes.Logo} />
                </Link>
                <Navigation items={navigationItems} />
            </div>
        </header>
    );
}

Header.propTypes = {
    contentClassName: PropTypes.string,
    navigationItems: PropTypes.arrayOf(
        PropTypes.shape({
            link: PropTypes.string.isRequired,
            label: PropTypes.string.isRequired
        })
    ).isRequired,
    homeLink: PropTypes.string.isRequired
};

Header.defaultProps = {
    contentClassName: ''
};

export default Header;
