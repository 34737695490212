import React, { Suspense } from 'react';
import { Route, Switch } from 'react-router-dom';
import { Helmet } from 'react-helmet';

import i18n from '../../config/i18n';

import Spinner from '../../components/Spinner/Spinner';

import Layout from './Layout/Layout';

import classes from './AppLayout.module.scss';

// Pages
const Home = React.lazy(() => import('../Home/Home'));
const About = React.lazy(() => import('../About/About'));
const Contact = React.lazy(() => import('../Contact/Contact'));
const Products = React.lazy(() => import('../Products/Products'));
const PrivacyPolicy = React.lazy(() => import('../PrivacyPolicy/PrivacyPolicy'));
const NotFound = React.lazy(() => import('../NotFound/NotFound'));

//TODO add og meta tags

const navigationItems = [
    // {
    //     link: '/loja',
    //     label: i18n.t('app.menu.store')
    // },
    {
        link: '/produtos',
        label: i18n.t('app.menu.products'),
        component: (
            <>
                <Helmet>
                    <title>{i18n.t('app.pages.title.products')}</title>
                    <link
                        rel='canonical'
                        href='https://www.visualdata.com.br/produtos'
                    />
                </Helmet>
                <Products contentClassName={classes.Content} />
            </>
        ),
    },
    {
        link: '/sobre',
        label: i18n.t('app.menu.about'),
        component: (
            <>
                <Helmet>
                    <title>{i18n.t('app.pages.title.about')}</title>
                    <link
                        rel='canonical'
                        href='https://www.visualdata.com.br/sobre'
                    />
                </Helmet>
                <About contentClassName={classes.Content} />
            </>
        ),
    },
    {
        link: '/contato',
        label: i18n.t('app.menu.contact'),
        component: (
            <>
                <Helmet>
                    <title>{i18n.t('app.pages.title.contact')}</title>
                    <link
                        rel='canonical'
                        href='https://www.visualdata.com.br/contato'
                    />
                </Helmet>
                <Contact contentClassName={classes.Content} />
            </>
        ),
    },
];

const routesWithoutNavigation = [
    {
        path: '/politica-de-privacidade',
        component: (
            <>
                <Helmet>
                    <title>Privacidade | Visual Data</title>
                </Helmet>
                <PrivacyPolicy contentClassName={classes.Content} />
            </>
        ),
    },
    {
        exact: true,
        path: '/',
        component: (
            <>
                <Helmet>
                    <title>{i18n.t('app.pages.title.home')}</title>
                    <link
                        rel='canonical'
                        href='https://www.visualdata.com.br/'
                    />
                </Helmet>
                <Home contentClassName={classes.Content} />
            </>
        ),
    },
    {
        component: (
            <>
                <Helmet>
                    <title>{i18n.t('app.pages.title.not_found')}</title>
                </Helmet>
                <NotFound contentClassName={classes.Content} />
            </>
        ),
    }
];

const loadingContent = (
    <div className={classes.Loading}>
        <Spinner className={classes.LoadingSpin} />
    </div>
);

function AppLayout() {
    return (
        <Layout
            navigationItems={navigationItems}
            homeLink='/'
            contentClassName={classes.Content}
        >
            <Suspense fallback={loadingContent}>
                <Switch>
                    {navigationItems.map(({ component, link }) => (
                        <Route
                            key={link}
                            path={link}
                            render={() => component}
                        />
                    ))}
                    {routesWithoutNavigation.map(
                        ({ exact, path, component }) => (
                            <Route
                                key={path || '/404'}
                                path={path}
                                exact={exact}
                                render={() => component}
                            />
                        )
                    )}
                </Switch>
            </Suspense>
        </Layout>
    );
}

export default AppLayout;
