import i18n from 'i18next';
import pt from './pt-br.json';

i18n.init({
    resources: {
        pt
    },
    lng: 'pt',

    fallbackLng: 'pt',
    whitelist: [
        'pt'
    ],
    interpolation: {
        escapeValue: false
    }
});

export default i18n;
