import roles from '../config/roles';

/**
 * Get user roles as { isAdmin: true, isDeveloper: false, ... }.
 *
 * @param {string[]} userRoles User roles.
 *
 * @return {object} Object containing isAdmin, isDeveloper, isLicensor and isRecruiter.
 */
export const getUserRoles = userRoles => {
    let isAdmin = false,
        isDeveloper = false,
        isLicensor = false,
        isRecruiter = false;

    userRoles.forEach(role => {
        // eslint-disable-next-line
        switch (role) {
            case roles.admin.role:
                isAdmin = true;
                break;
            case roles.developer.role:
                isDeveloper = true;
                break;
            case roles.licensor.role:
                isLicensor = true;
                break;
            case roles.recruiter.role:
                isRecruiter = true;
                break;
        }
    });

    return { isAdmin, isDeveloper, isLicensor, isRecruiter };
};

/**
 * Check if user has an allowed role.
 *
 * @param {string[]} userRoles User roles.
 * @param {string[]} allowedRoles Allowed roles.
 *
 * @return {boolean} True if user has an allowed role.
 */
export const isUserAllowed = (userRoles, allowedRoles) => userRoles.some(role => allowedRoles.includes(role));
